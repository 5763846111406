<template>
  <div class="select_all">
    <div class="selection_wrap">
      <singin-select
        ref="singinSeleceCountry"
        v-model="selectedCountry"
        class-name="country"
        label="COUNTRY"
        :list="countryList"
        @getCountryList="getCountryListEvent"
      />
      <singin-select
        ref="singinSeleceType"
        v-model="selectedType"
        class-name="type"
        label="TYPE"
        :list="typeList"
        @getTypeList="getTypeListEvent"
      />
      <singin-select
        ref="singinSeleceYear"
        v-model="selectedYear"
        class-name="year"
        label="YEAR"
        :list="yearList"
        @getYearList="getYearListEvent"
      />
    </div>
    <div class="btn_wrap">
      <div class="reset_data" @click="resetDataEvent">RESET</div>
      <div class="search_data" @click="searchDataEvent">SEARCH</div>
    </div>
  </div>
</template>

<script>
import SinginSelect from "./SinginSelect.vue";
import { getSelectList } from "../api/home";
export default {
  components: {
    SinginSelect,
  },
  props: {},
  data() {
    return {
      dropVisible: false,
      countryList: [],
      typeList: [],
      yearList: [],
    };
  },
  created() {
    const query = JSON.parse(JSON.stringify(this.$route.query));
    // console.log(query, "query项目页参数");
    if (Object.keys(query).length > 0) {
      this.getSelectListEvent(query.country, query.type, query.year);
      this.selectedCountry = query.country;
      this.selectedType = query.type;
      this.selectedYear = query.year;
      this.$emit("sendList", query);
    } else {
      this.getSelectListEvent("", "", "");
      this.selectedCountry = "";
      this.selectedType = "";
      this.selectedYear = "";
    }
  },
  mounted() {},
  computed: {
    selectedCountry: {
      get() {
        return this.$store.state.project.selectedData.country;
      },
      set(value) {
        this.$store.commit("project/setSelected", { key: "country", value });
      },
    },
    selectedType: {
      get() {
        return this.$store.state.project.selectedData.type;
      },
      set(value) {
        this.$store.commit("project/setSelected", { key: "type", value });
      },
    },
    selectedYear: {
      get() {
        return this.$store.state.project.selectedData.year;
      },
      set(value) {
        this.$store.commit("project/setSelected", { key: "year", value });
      },
    },
  },
  methods: {
    handleOptions(key) {
      this.dropVisible = false;
    },
    // 展示列表接口
    async getSelectListEvent(country, type, year) {
      const data = {
        country,
        type,
        year,
      };
      const res = await getSelectList(data);
      this.countryList = this.regroupValueEvent(res.data.country);
      this.typeList = this.regroupValueEvent(res.data.type);
      this.yearList = this.regroupValueEvent(res.data.year);
      // console.log(res.data, "展示列表筛选接口参数");
    },

    // 自定义接收国家数据
    getCountryListEvent(key) {
      this.selectedCountry = key;
      this.getSelectListEvent(
        this.selectedCountry,
        this.selectedType,
        this.selectedYear
      );
    },
    // 自定义接收类型数据
    async getTypeListEvent(key) {
      this.selectedType = key;
      this.getSelectListEvent(
        this.selectedCountry,
        this.selectedType,
        this.selectedYear
      );
    },
    // 自定义接收年份数据
    async getYearListEvent(key) {
      this.selectedYear = key;
      this.getSelectListEvent(
        this.selectedCountry,
        this.selectedType,
        this.selectedYear
      );
    },

    // 重组后端数据
    regroupValueEvent(res) {
      const newArr = [];
      res.forEach((item) => {
        newArr.push({
          value: item,
          label: item,
        });
      });
      newArr.unshift({
        value: "",
        label: "All",
      });
      return newArr;
    },
    // 重置
    resetDataEvent() {
      this.getSelectListEvent("", "", "");

      this.$emit("resetProjectResult");
      this.$emit("resetProject");
      this.selectedCountry = "";
      this.selectedType = "";
      this.selectedYear = "";
    },
    // 搜索参数
    searchDataEvent() {
      const data = {
        country: this.selectedCountry,
        type: this.selectedType,
        year: this.selectedYear,
      };
      this.$emit("toSearchResultPage", data);
      // console.log(data, "搜索参数");
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_fun.scss";
.select_all {
  width: vw(1718);
  height: vw(104);
  margin: 0 auto;
  padding: 0 vw(28) 0 vw(40);
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #ffffff;
  .selection_wrap {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
  .btn_wrap {
    width: vw(248);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .reset_data {
      width: vw(104);
      height: vw(56);
      line-height: vw(56);
      text-align: center;
      border: 1px solid #979797;
      cursor: pointer;
      font-family: Helvetica;
    }
    .search_data {
      width: vw(104);
      height: vw(56);
      line-height: vw(56);
      text-align: center;
      color: #fff;
      background: #000;
      cursor: pointer;
      font-family: Helvetica;
    }
  }
}
.select_all {
  // min-width: 1200px 表示当屏幕宽度大于等于800px时，该样式生效。
  // max-width: 1200px 表示当屏幕宽度小于等于800px时，该样式生效。
  @media screen and (max-width: 1200px) {
    .selection_wrap {
      justify-content: normal;
    }
    .btn_wrap {
      width: 260px;
      .reset_data,
      .search_data {
        width: 100px;
      }
    }
  }
}
</style>
