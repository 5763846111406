<template>
  <div ref="selectRef" :class="['select_singin', className]">
    <span class="label">{{ label }}</span>
    <div
      :class="['select_drow', className]"
      @click.stop="dropVisible = !dropVisible"
    >
      <div class="value">
        <span>{{ value || "All" }}</span>
        <img
          :class="['down', dropVisible ? 'active' : '']"
          src="../assets/images/down.png"
          alt=""
        />
      </div>
      <div
        v-if="dropVisible"
        :class="['select_scroll_wrap', className]"
        data-simplebar
        data-simplebar-auto-hide="false"
      >
        <simple-bar>
          <div class="select_drow_options">
            <p
              v-for="item in list"
              :key="item.value"
              :value="item.value"
              @click.stop="handleOptions(item.value)"
            >
              {{ item.label }}
            </p>
          </div>
        </simple-bar>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SinginSelect",
  model: {
    prop: "value",
    event: "update:value",
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: "",
    },
    className: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dropVisible: false,
    };
  },
  methods: {
    handleOptions(key) {
      this.dropVisible = false;
      this.$emit("update:value", key);
      this.$emit("getCountryList", key);
      this.$emit("getTypeList", key);
      this.$emit("getYearList", key);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_fun.scss";
.select_singin {
  height: vw(56);
  margin-right: vw(60);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:last-child {
    margin-right: vw(40);
  }
  .label {
    font-size: rem(28);
    font-weight: 500;
    color: #000000;
    font-family: Helvetica;
  }

  .select_drow {
    position: relative;
    width: vw(320);
    height: 100%;
    margin-left: vw(42);
    padding: 0 vw(30) vw(26);
    color: #000;
    cursor: pointer;
    box-shadow: 0 0 0 1px #979797;
    &.year {
      width: vw(170);
    }
    .value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        display: block;
        width: 100%;
        height: 100%;
        font-size: rem(28);
        font-weight: 100;
        line-height: vw(56);
        font-family: Helvetica;
      }

      .down {
        width: vw(26);
        transition: all 300ms ease;
        &.active {
          transform: rotate(180deg);
        }
      }
    }
    .select_scroll_wrap {
      position: absolute;
      bottom: vw(56);
      right: vw(-1);
      width: vw(322);
      max-height: vw(225);
      &.year {
        width: vw(172);
      }
      .select_drow_options {
        border: 1px solid #979797;
        background-color: #fff;
        border-bottom: none;
        p {
          width: 100%;
          height: vw(56);
          line-height: vw(56);
          font-size: rem(28);
          padding: 0 vw(30);
          font-weight: 300;
          color: #000000;
          font-family: Helvetica;
          &:hover {
            background: #ccc;
          }
        }
      }
    }
  }
}
// min-width: 800px 表示当屏幕宽度大于等于800px时，该样式生效。
// max-width: 800px 表示当屏幕宽度小于等于800px时，该样式生效。

.select_singin {
  @media screen and (max-width: 1200px) {
    .label {
      font-size: rem(20);
    }

    .select_drow {
      margin-left: 10px;
      .value {
        span {
          font-size: rem(20);
        }
      }
      .select_scroll_wrap {
        .select_drow_options {
          p {
            height: auto;
            font-size: rem(20);
          }
        }
      }
    }
  }
}
</style>
