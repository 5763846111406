<template>
  <div class="SearchResult" @click.stop="storeBodyClick">
    <my-header
      isShowLogo="show"
      :logoSrc="logo"
      path="home"
      page="pro"
    ></my-header>
    <div class="tips">Double click to enter or exit full screen mode</div>

    <div class="content_wrap">
      <template v-if="swiperImgList.length > 1">
        <div class="my_swiper_wrap" ref="my_swiper_wrap">
          <div class="mySwiper swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in swiperImgList"
                :key="index"
              >
                <div class="project_item" @click="nextStepEvent(item.content)">
                  <img class="images" :src="item.images" />
                  <div class="next">
                    <em>View</em>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="prev_list">
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
          <div class="total_num" v-if="swiperImgList.length > 2">
            {{ swiperImgList.length }} Projects in Total
          </div>
        </div>
      </template>
      <template v-if="swiperImgList.length === 1">
        <div class="my_swiper_wrap">
          <figure class="project_item" @click="nextStepEvent(contentUrl)">
            <img class="images" :src="imgUrl" />
            <div class="next">
              <em>View</em>
            </div>
          </figure>
        </div>
      </template>
      <template v-if="swiperImgList.length < 1">
        <div class="my_swiper_wrap">
          <figure class="project_item">
            <img
              src="../assets/images/product/placeholder-map.png"
              alt=""
              class="images"
            />
          </figure>
        </div>
      </template>
      <SearchBar
        ref="searchBarRef"
        @resetProjectResult="resetProjectResultPage"
        @sendList="getExhibitionListEvent"
        @toSearchResultPage="searchResultEvent"
      ></SearchBar>
    </div>
  </div>
</template>

<script>
import Swiper from "../utils/swiper-bundle.min.js";
import logo from "../assets/images/product/logo.png";
import SearchBar from "../components/SearchBar.vue";
import MyHeader from "../components/MyHeader.vue";
import { getExhibitionList } from "../api/home";
export default {
  components: {
    MyHeader,
    SearchBar,
  },
  props: {},
  data() {
    return {
      logo,
      imgUrl: "",
      contentUrl: "",
      queryPath: "",
      swiperImgList: [],
    };
  },
  created() {},

  mounted() {},
  computed: {},
  methods: {
    initSwiper() {
      let mySwiper = new Swiper(".mySwiper", {
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        slidesPerView: 2,
        spaceBetween: 30,
        autoplay: {
          delay: 2000,
          stopOnLastSlide: true,
        },
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          observerUpdate: function () {
            // console.log("监测到DOM变化，更新Swiper");
          },
        },
      });
    },
    // 接收列表数据接口
    async getExhibitionListEvent(data) {
      const res = await getExhibitionList(data);
      this.swiperImgList = res.data.data;
      this.imgUrl = res.data.data[0].images;
      this.contentUrl = res.data.data[0].content;
      // this.queryPath = `searchResult?country=${data.country}type=${data.type}&year=${data.year}`;
      this.$nextTick(() => {
        // 初始化swiper
        this.initSwiper();
      });
    },

    // 跳转详情页
    nextStepEvent(content) {
      this.$router.push(`/project-detail?content=${content}`);
    },
    // 搜索结果
    async searchResultEvent(data) {
      this.$router.push(
        `/searchResult?country=${data.country}&type=${data.type}&year=${data.year}`
      );
      // window.location.reload();
      setTimeout(() => {
        this.getExhibitionListEvent(data);
      }, 500);
    },
    resetProjectResultPage() {
      this.$router.push("/searchResult");
    },
    storeBodyClick() {
      this.$refs.searchBarRef.$refs.singinSeleceCountry.dropVisible = false;
      this.$refs.searchBarRef.$refs.singinSeleceType.dropVisible = false;
      this.$refs.searchBarRef.$refs.singinSeleceYear.dropVisible = false;
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_fun.scss";
@import "../utils/swiper-bundle.min.css";
.SearchResult {
  position: relative;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  background-image: url("../assets/images/product/bg1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .tips {
    position: fixed;
    top: vw(80);
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 16px !important;
    font-family: Helvetica;
    opacity: 0.5;
  }
  .content_wrap {
    width: 100%;
    height: vw(801);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: vw(34);
    .my_swiper_wrap {
      position: relative;
      width: vw(1718);
      height: vw(697);
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.2);
      border: 1px solid #979797;
      backdrop-filter: blur(15px);
      color: #fff;
      .swiper-container {
        width: vw(1466);
        height: vw(697);
        padding-top: vw(50);
        margin: 0 auto;
        overflow: hidden;
      }
    }
    .project_item {
      position: relative;
      width: vw(715);
      overflow: hidden;
      cursor: pointer;

      .next {
        position: absolute;
        right: vw(40);
        bottom: vw(40);
        width: vw(150);
        height: vw(56);
        font-size: rem(28);
        font-weight: 500;
        color: #ffffff;
        background: #000000;
        cursor: pointer;
        em {
          display: inline-block;
          width: 100%;
          height: 100%;
          padding-left: vw(34);
          line-height: vw(56);
          position: relative;
          font-style: normal;
          font-family: Helvetica;
          &::before {
            content: "";
            position: absolute;
            top: vw(19);
            right: vw(25);
            border: 2px solid #fff;
            width: vw(18);
            height: vw(18);
            border-left-color: transparent;
            border-top-color: transparent;
            transform: rotate(-45deg); //右箭头
          }
        }
      }
    }

    .prev_list {
      height: 100%;
      .swiper-button-next,
      .swiper-button-prev {
        color: #fff;
      }
      .swiper-button-prev {
        margin-left: vw(48);
        left: 0;
      }
      .swiper-button-next {
        margin-right: vw(48);
        right: 0;
      }
    }
    .total_num {
      position: absolute;
      bottom: vw(20);
      z-index: 1;
      font-size: vw(20);
      font-weight: bold;
      color: #ffffff;
      font-family: Helvetica;
    }
  }
}

.SearchResult {
  // min-width: 1200px 表示当屏幕宽度大于等于800px时，该样式生效。
  // max-width: 1200px 表示当屏幕宽度小于等于600px时，该样式生效。
  @media screen and (max-width: 1200px) {
    .content_wrap {
      margin-top: 90px;

      .project_item {
        width: 434px;
      }
    }
  }
}
</style>
